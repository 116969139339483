import React, { Component } from 'react';
import { stringify } from 'qs';
import PropTypes from 'prop-types'
// import objSafe from 'obj-safe';
// const debug = require('debug')('Pinboard:UI:ImGBox');
class ImgBox extends Component {
    render() {
        // debug( this.props )
        let {
            // imgDetails,
            defaultFillColor,
            borderRadius,
            // imgBasePath,
            src,
            maxHeight,
            imgGrowToWidth,
            // imgPrefix,
            height,
            width,
            style,
            vignette,
            vColor,
            vRadius,
            cropPosition,
            fillWidthSolid,
            title
        } = this.props;
        // console.log({ doNotTrack });

        if (maxHeight === 0) maxHeight = null;

        const accentColor = ('#' + defaultFillColor) || '777';
        vColor = vColor || 'rgba(0,0,0,0.75)';
        vRadius = vRadius || 25;

        // the width is like our single constant
        let imgH = height;
        let imgW = width;

        // shrink or grow our image
        if ((imgW > width) || imgGrowToWidth) {
            let scaleFactor = width / imgW;
            imgH = parseInt(imgH * scaleFactor, 10);
            imgW = imgW * scaleFactor;
        }

        // [x] need whichever is smallest but not 0
        let notNull = [height, maxHeight, imgH].filter(val => val);
        // let boxH = Math.max(...notNull);
        let boxH = Math.min(...notNull);
        boxH = Math.ceil(boxH);
        imgW = Math.ceil(imgW);
        // console.log({ imgW, imgH, boxH });

        // SSR never has navigator.doNotTrack
        // [] move this logic out of ImgBox and send src if necessary
        if (isNaN(imgW)) imgW = null;
        // var src = src ? src : imgBasePath + stringify({ imgWidth: imgW, imgPrefix, filename: imgDetails.filename });
        // if ((typeof window !== 'undefined') && imgDetails.url && !navigator.doNotTrack) src = imgDetails.url;

        // if (borderRadius) borderRadius = Math.min(borderRadius, boxH / 2, imgW / 2);
        let styles = {
            cropContainer: {
                display: 'block',
                margin: 'auto',
                borderRadius: borderRadius || `inherit`,
                // borderRadius: `${borderRadius}px`,
                // borderTopLeftRadius: `inherit`,
                // borderTopRightRadius: `inherit`,
                width: fillWidthSolid ? width : (imgW || '100%'),
                backgroundColor: accentColor,
                height: boxH,
                backgroundImage: `url(${src})`,
                backgroundPosition: cropPosition || 'top',
                backgroundRepeat: 'no-repeat',
                // [] this is now broken for img cropping
                backgroundSize: 'cover',
                // [] this is causing img to not cover background-color by ~1px;
                // backgroundSize: (height || !width) ? 'cover' : `${imgW}px ${imgH}px`,
                boxShadow: vignette ? `inset 0 0 ${vRadius}px ${vColor}` : null,
                maxWidth: '100%'
            }
        }

        // debug(styles.cropContainer);

        return (
            <div
                className="img-box"
                style={{ ...styles.cropContainer, ...style }}
                title={title}
            ></div>
        )
    }
}

ImgBox.propTypes = {
    // imgDetails not required because some pins have no image
    // imgDetails: PropTypes.object,
    defaultFillColor: PropTypes.string, // hex without # prefix
    // imgBasePath: PropTypes.string.isRequired,
    src: PropTypes.string,
    width: PropTypes.number,
    cropPosition: PropTypes.string, // has default 'top'
    vignette: PropTypes.bool,
    imgGrowToWidth: PropTypes.bool,
    vColor: PropTypes.string, // has default
    vRadius: PropTypes.number, // has default
    borderRadius: PropTypes.number // has default
}


export default ImgBox;