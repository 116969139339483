import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 1230px;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  /* @media screen  and (max-width: 1023px) {
    margin-left: 10px;
    margin-right: 10px;
  } */
`

const Wrap = ({children}) => {
return (
    <Container>
        {children}
    </Container>
)
};



Wrap.propTypes = {};

export default Wrap;